import React from 'react'
import { Navbar } from '../components/Navbar'
import { Foot } from '../components/Foot'
import donate from "./../assets/icons/donate.png"
import groceries from "./../assets/icons/groceries.png"

export const Donaciones = () => {
  const redirectToPaypal = () => {
    window.open("https://www.paypal.com/paypalme/ibamad")
  }
  return (
    <div className="flex flex-col min-h-svh">
      <Navbar/>
      <div className="flex-grow bg-gray-100">
        <div className="relative md:bg-fixed bg-cover bg-top bg-[url('./assets/img/donations-food.jpg')] py-32 w-full shadow-lg">
        
        {/* Semi-transparent overlay */}
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        {/* Content with relative positioning to stay on top of overlay */}
          <div className="relative z-10 grid grid-cols-6 animate-fade">
            <div></div>
            <div className="xl:col-span-4 lg:col-span-4 col-span-full">
              <h1 className="text-4xl text-center font-semibold mb-6 text-white">Donaciones</h1>
              <p className="text-white text-center text-2xl font-light tracking-wide mb-10">
              ¿Quieres contribuir al crecimiento de la Iglesia y la expansión del Reino de Dios?
              </p>
            </div>
            <div></div>
          </div>
        </div>
      </div>

      <div className="py-20 grid grid-cols-6 animate-fade w-full md:px-8 sm:px-8">
        <div></div>
        <div className="xl:col-span-4 lg:col-span-4 col-span-full">
          <div className="grid md:grid-cols-2 text-center gap-y-16">
            
            {/* Tithe, donations and offerings */}
            <div className="flex flex-col items-center gap-y-1">
              <img src={donate} alt="Diezmos y ofrendas" className="size-24" />
              <p className="font-semibold text-2xl">Diezmos y Ofrendas</p>
              <p className="text-lg px-4 py-2 text-gray-600">
                Nos ayuda a solventar gastos como la renta del local, salarios, ministerios y donaciones a conferencistas ocasionales.
              </p>
              <p className="text-lg text-gray-600 italic">Robero Zamora Guerrero</p>
              <p className="text-lg text-gray-600"><span className="font-semibold">CLABE</span> 646650146400478353</p>
              <p className="text-lg text-gray-600"><span className="font-semibold">Tarjeta Física</span> 4217 4700 4244 1861</p>
              <div className="flex justify-center mt-4">
                <button 
                  type="button" 
                  onClick={redirectToPaypal} 
                  className="border-2 border-blue-600 bg-blue-600 text-white hover:bg-slate-400 hover:border-slate-400 hover:text-white transition-all px-8 py-2 rounded text-lg font-semibold flex-row flex shadow">
                    PayPal
                </button>
              </div>

            </div>

            {/* Groceries for the Hospital */}
            <div className="flex flex-col items-center gap-y-1">
              <img src={groceries} alt="Facebook" className="size-24" />
              <p className="font-semibold text-2xl">Despensas para Hospital</p>
              <p className="text-lg px-4 py-2 text-gray-600">
                Solemos visitar hospitales para compartir el Evangelio y dar palabras de aliento. En cada visita, preparamos una despensa para compartir con los que lo deseen.
              </p>
              <p className="text-lg px-4 py-2 text-gray-600">
                Para conocer cómo contribuir, visítanos y pide informes a cualquiera de los servidores presentes. De forma alternativa, envíanos un mensaje por correo electrónico o Facebook.
              </p>
              <p className="text-lg text-gray-600 italic">Iglesia Bíblica AMAD</p>
            </div>
          </div>
        </div>
      </div>
      <Foot/>
    </div>
  )
}
