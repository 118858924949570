import React from 'react'
import { Navbar } from '../components/Navbar'
import { Foot } from '../components/Foot'
import email from "./../assets/icons/outlook.png"
import facebook from "./../assets/icons/facebook_bg.png"
import youtube from "./../assets/icons/youtube.png"
import spotify from "./../assets/icons/spotify.png"


export const Contacto = () => {
  const redirectToFacebook = () => {
    window.open("https://www.facebook.com/iglesiabiblicaAMAD/")
  }

  const redirectToSpotify = () => {
    window.open("https://open.spotify.com/show/1fg1okdrXzheNFg5HqnEfN")
  }

  const redirectToYoutube = () => {
    window.open("https://www.youtube.com/@IglesiaBiblicaAMAD/featured")
  }

  return (
    <div className="flex flex-col min-h-svh">
  <Navbar />
  <div className="flex-grow bg-gray-100">
    {/* Background image section with overlay */}
    <div className="relative md:bg-fixed bg-cover bg-center bg-[url('./assets/img/church-members.jpg')] py-32 w-full shadow-lg">
      
      {/* Semi-transparent overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-60"></div>

      {/* Content with relative positioning to stay on top of overlay */}
      <div className="relative z-10 grid grid-cols-6 animate-fade">
        <div></div>
        <div className="xl:col-span-4 lg:col-span-4 col-span-full">
          <h1 className="text-4xl text-center font-semibold mb-6 text-white">Contacto</h1>
          <p className="text-white text-center text-2xl font-light tracking-wide mb-10">
            ¿Qué necesitas? Estamos para servirte.
          </p>
        </div>
        <div></div>
      </div>
    </div>

    {/* Contact Information Section */}
    <div className="py-20 grid grid-cols-6 animate-fade w-full md:px-8 sm:px-8">
      <div></div>
      <div className="xl:col-span-4 lg:col-span-4 col-span-full">
        <div className="grid md:grid-cols-2 text-center gap-y-16">
          
          {/* Email Contact */}
          <div className="flex flex-col items-center gap-y-1">
            <img src={email} alt="Correo Electrónico" className="size-20" />
            <p className="font-semibold text-2xl">Correo Electrónico</p>
            <p className="text-lg px-4 py-2 text-gray-600">
              ¿Necesitas consejería, orientación o dudas con respecto al Evangelio?
            </p>
            <p className="text-lg text-gray-600 italic">Pastor Robero Zamora</p>
            <p className="text-lg text-gray-600 underline">robzamo@outlook.com</p>
          </div>

          {/* Facebook Contact */}
          <div className="flex flex-col items-center gap-y-1">
            <img src={facebook} alt="Facebook" className="size-20" />
            <p className="font-semibold text-2xl">Facebook</p>
            <p className="text-lg px-4 py-2 text-gray-600">
              Publicamos devocionales todas las noches y transmitimos el culto dominical.
            </p>
            <p className="text-lg text-gray-600 italic">Iglesia Bíblica AMAD</p>
            <div className="flex justify-center mt-4">
              <button 
                type="button" 
                onClick={redirectToFacebook} 
                className="border-2 border-blue-600 bg-blue-600 text-white hover:bg-slate-400 hover:border-slate-400 hover:text-white transition-all px-8 py-2 rounded text-lg font-semibold flex-row flex shadow">
                Visitar
              </button>
            </div>
          </div>

          {/* YouTube Contact */}
          <div className="flex flex-col items-center gap-y-1">
            <img src={youtube} alt="YouTube" className="size-24" />
            <p className="font-semibold text-2xl">YouTube</p>
            <p className="text-lg px-4 py-2 text-gray-600">
              Conozca del Evangelio con devocionales y videos.
            </p>
            <p className="text-lg text-gray-600 italic">Iglesia Bíblica AMAD</p>
            <div className="flex justify-center mt-4">
              <button 
                type="button" 
                onClick={redirectToYoutube} 
                className="border-2 border-red-600 bg-red-600 text-white hover:bg-slate-400 hover:border-slate-400 hover:text-white transition-all px-8 py-2 rounded text-lg font-semibold flex-row flex shadow">
                Visitar
              </button>
            </div>
          </div>

          {/* Spotify Contact */}
          <div className="flex flex-col items-center gap-y-1 ">
            <img src={spotify} alt="Spotify" className="size-24" />
            <p className="font-semibold text-2xl">Spotify</p>
            <p className="text-lg px-4 py-2 text-gray-600">
              Escuche podcasts y devocionales centrados en la Palabra de Dios.
            </p>
            <p className="text-lg text-gray-600 italic">Iglesia Bíblica AMAD</p>
            <div className="flex justify-center mt-4">
              <button 
                type="button" 
                onClick={redirectToSpotify} 
                className="border-2 border-green-600 bg-green-600 text-white hover:bg-slate-400 hover:border-slate-400 hover:text-white transition-all px-8 py-2 rounded text-lg font-semibold flex-row flex shadow">
                Visitar
              </button>
            </div>
          </div>

        </div>
      </div>
      <div></div>
    </div>
  </div>
  <Foot />
</div>

  )
}
