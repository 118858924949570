import React from 'react'
import facebook from "./../assets/icons/facebook.png"
import spotify from "./../assets/icons/spotify.png"
import youtube from "./../assets/icons/youtube.png"

export const Foot = () => {
  return (
    <footer className="w-full bg-gray-800">
      <div className="flex flex-row justify-around py-6">
        <div className="flex flex-col justify-center">
          <div className="text-slate-500"></div>
        </div>
        
        <div className="flex gap-x-8">
          <div className="flex flex-row ">
            <img src={facebook} alt="" className="size-6"/>
            <a className="text-slate-500 hover:text-slate-100 transition-all" href="https://www.facebook.com/iglesiabiblicaAMAD/" target="_blank" rel="noreferrer">Facebook</a>
          </div>
          <div className="flex flex-row">
            <img src={spotify} alt="" className="size-6"/>
            <a className="text-slate-500 hover:text-slate-100 transition-all" href="https://open.spotify.com/show/1fg1okdrXzheNFg5HqnEfN" target="_blank" rel="noreferrer">Spotify</a>
          </div>
          <div className="flex flex-row">
            <img src={youtube} alt="" className="size-6"/>
            <a className="text-slate-500 hover:text-slate-100 transition-all" href="https://www.youtube.com/@IglesiaBiblicaAMAD/featured" target="_blank" rel="noreferrer">YouTube</a>
          </div>
        </div>

        {/**For future usage. Rigt now it helps social media icons stay centered */}
        <div className="flex flex-col gap-y-2">
          <div></div>
        </div>
      </div>
      <div className="text-center text-sm text-slate-400 pb-2">Iglesia Bíblica AMAD &#169;</div>
    </footer>
  )
}
