import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const MobileNav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="md:invisible">
      {/* Hamburger Button */}
      <button id="mobile-nav" className="group" onClick={toggleMenu}>
        <div className="space-y-1">
          <div className="w-6 h-1 bg-white"></div>
          <div className="w-6 h-1 bg-white"></div>
          <div className="w-6 h-1 bg-white"></div>
        </div>
      </button>

      {/* Mobile Menu */}
      <ul
        className={`z-50 bg-black w-screen pb-10 absolute right-0 duration-150 flex flex-col space-y-3 justify-end text-gray-400 ${
          isMenuOpen ? 'top-0' : '-top-full'
        }`}
      >
        {/* Close Button */}
        <button className="px-10 py-8 relative ml-auto" onClick={toggleMenu}>
          <div className="w-6 h-1 rotate-45 absolute bg-white"></div>
          <div className="w-6 h-1 -rotate-45 absolute bg-white"></div>
        </button>

        {/* Navigation Links */}
        <li className="flex justify-center w-full hover:bg-[#202020]">
          <Link
            to="/"
            className="block w-full text-center py-4"
            onClick={toggleMenu}
          >
            Inicio
          </Link>
        </li>
        <li className="flex justify-center w-full hover:bg-[#202020]">
          <Link
            to="/nuestra-fe"
            className="block w-full text-center py-4"
            onClick={toggleMenu}
          >
            Nuestra Fe
          </Link>
        </li>
        <li className="flex justify-center w-full hover:bg-[#202020]">
          <Link
            to="/contacto"
            className="block w-full text-center py-4"
            onClick={toggleMenu}
          >
            Contacto
          </Link>
        </li>
        <li className="flex justify-center w-full hover:bg-[#202020]">
          <Link
            to="/donaciones"
            className="block w-full text-center py-4"
            onClick={toggleMenu}
          >
            Donaciones
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default MobileNav;
