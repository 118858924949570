import { Inicio } from './pages/Inicio';
import { NuestraFe } from './pages/NuestraFe';
import { Routes, Route } from 'react-router-dom';
import { Contacto } from "./pages/Contacto";
import { Donaciones } from './pages/Donaciones';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Inicio/>} />
        <Route path="/nuestra-fe" element={<NuestraFe/>} />
        <Route path="/contacto" element={<Contacto/>} />
        <Route path="/donaciones" element={<Donaciones/>} />
      </Routes>
    </div>
  );
}

export default App;
