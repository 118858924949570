import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import amad from "./../assets/icons/amad-blanco.png"
import { useLocation } from "react-router-dom"
import { useState } from 'react'
import MobileNav from './MobileNav'

export const Navbar = () => {
  const location = useLocation();
  const [bg, setBg] = useState("bg-transparent animate-fade w-screen h-screen");
  let navbar_style = "grid grid-cols-6";
   
  useEffect(()=> {
    if(location.pathname === "/") {
      setBg(" bg-transparent animate-fade");
    } else {
      setBg(" bg-black");
    }
   }, [location]);

  return (
    <nav className={navbar_style + bg}>
      <div></div>
      <div className="xl:col-span-4 lg:col-span-4 md:px-8 sm:px-8 py-2 col-span-full">
        <div className="flex justify-between px-2">
          <div>
          <span>
            <Link to="/">
              <img src={amad} alt="AMAD logo" className="h-12" />
            </Link>
          </span>
          </div>

          <div className="flex">
            <ul className="md:flex hidden place-items-center gap-7 uppercase font-semibold content-center text-lg text-white overflow-x-hidden">
              <li><Link to="/" className="hover:text-gray-400 transition-all">Inicio</Link></li>
              <li><Link to="/nuestra-fe" className="hover:text-gray-400 transition-all">Nuestra Fe</Link></li>
              <li><Link to="/contacto" className="hover:text-gray-400 transition-all">Contacto</Link></li>
              <li><Link to="/donaciones" className="hover:text-gray-400 transition-all">Donaciones</Link></li>
            </ul>

            {/* Hamburguer mobile menu */}
            <div className="self-center">
              <MobileNav/>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </nav>
  )
}