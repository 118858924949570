import React, { useEffect, useState } from 'react';
import { Navbar } from '../components/Navbar';
import { motion } from 'framer-motion';

export const HeroMain = ({ scrollToMisionRef }) => {
  // Framer motion variants for fade-up effect
  const fadeUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: 'easeOut' } }
  };

  // Scroll handler for the button with custom 2-second duration
  const scrollToMision = () => {
    if (scrollToMisionRef.current) {
      const targetPosition = scrollToMisionRef.current.getBoundingClientRect().top + window.pageYOffset;
      const startPosition = window.pageYOffset;
      const distance = targetPosition - startPosition;
      const duration = 2000; // 2 seconds
      let startTime = null;

      const scrollAnimation = (currentTime) => {
        if (!startTime) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
        window.scrollTo(0, run);
        if (timeElapsed < duration) requestAnimationFrame(scrollAnimation);
      };

      const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      requestAnimationFrame(scrollAnimation);
    }
  };

  // Floating animation for the arrow
  const float = {
    y: [0, -20, 0],  // Move up by 20px and back
    transition: {
      duration: 3,  // 3 seconds per cycle
      ease: 'easeInOut',
      repeat: Infinity,  // Infinite loop
      repeatType: 'loop',  // Continuous looping
    }
  };

  // State for typewriting effect
  const [displayedText, setDisplayedText] = useState('');
  const fullText = '«En esto conocerán todos que ustedes son mis discípulos, si se aman unos a otros». ―Juan 13:35';

  useEffect(() => {
    // Typewriting effect logic
    const timeout = setTimeout(() => {
      setDisplayedText(fullText.slice(0, displayedText.length + 1));
    }, 50); // 50ms delay between each letter

    return () => clearTimeout(timeout);
  }, [displayedText]);

  return (
    <div className="shadow-2xl">
      <div alt="Juan 13:35" className="min-h-screen md:bg-fixed bg-[url('./assets/img/hands-up-opaque.png')] w-full h-full bg-no-repeat bg-cover bg-bottom">
        <Navbar/>
        
        {/* Center content vertically and horizontally */}
        <div className="flex flex-col justify-center items-center min-h-screen text-center text-white">
          {/* Title fades in */}
          <motion.h1
            className="text-4xl mb-10 px-8"
            initial="hidden"
            animate="visible"
            variants={fadeUp}
          >
            Alcanzar. Mostrar. Adorar. Dar.
          </motion.h1>

          {/* Verse writes itself */}
          <motion.p
            className="text-2xl font-light tracking-wide mb-10 px-8"
            initial="hidden"
            animate="visible"
            variants={fadeUp}
            onAnimationComplete={() => {
              // Start the typing animation after title is fully visible
              // Reset displayed text setDisplayedText('');
            }}
          >
            {displayedText}
          </motion.p>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeUp}
          >
            {/* Scroll to the Mision section when button is clicked */}
            <button
              id="scroll-to-mission"
              type="button"
              onClick={scrollToMision}
              className="transition-all pt-8"
            >
              <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-10 h-10 text-white mt-8"
                animate={float}
              >
                <path fillRule="evenodd" d="M11.47 13.28a.75.75 0 0 0 1.06 0l7.5-7.5a.75.75 0 0 0-1.06-1.06L12 11.69 5.03 4.72a.75.75 0 0 0-1.06 1.06l7.5 7.5Z" clipRule="evenodd" />
                <path fillRule="evenodd" d="M11.47 19.28a.75.75 0 0 0 1.06 0l7.5-7.5a.75.75 0 1 0-1.06-1.06L12 17.69l-6.97-6.97a.75.75 0 0 0-1.06 1.06l7.5 7.5Z" clipRule="evenodd" />
              </motion.svg>
            </button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};
