import React from 'react';
import { Navbar } from '../components/Navbar';
import { Foot } from '../components/Foot';
import { motion, useScroll } from "framer-motion";

export const NuestraFe = () => {
  const { scrollYProgress } = useScroll();
  const li_style = "mb-8 leading-7";
  
  const faith_statements = [
    { content: "1. CREEMOS que la Sagrada Biblia, compuesta por el Antiguo y Nuevo Testamento, es inspirada por Dios, infalible y sin error en los manuscritos originales y que es la autoridad suprema y final en todos los asuntos de vida y fe (2 Timoteo 3:16,17; 2 Pedro 1:20,21; Mateo 5:18).", id: 1 },
    { content: "2. CREEMOS que hay un sólo Dios creador del universo que ha existido eternamente en tres Personas iguales: Padre, Hijo y Espíritu Santo (Deuteronomio 6:4; 2 Corintios 13:14; Efesios 2:18).", id: 2 },
    { content: "3. CREEMOS que Dios Hijo se encarnó en la Persona de Jesucristo y que fue engendrado por el Espíritu Santo, nacido de la virgen María, y que es verdadero Dios y verdadero Hombre, y que es el único Salvador para el pecador (Juan 1:1,2,14; Mateo 1:21-25; Lucas 1:35; Juan 8:58; Filipenses 2:5-11; 1 Timoteo 2:5).", id: 3 },
    { content: "4. CREEMOS que el Señor Jesucristo murió en nuestro lugar como un sacrificio por nuestros pecados y que todos los que creen en El son eternamente salvos del castigo merecido, el infierno (Romanos 3:24,25; 5:8; 1 Pedro 2:24; Efesios 1:7; 1 Corintios 15:3,4).", id: 4 },
    { content: "5. CREEMOS que Jesucristo resucitó corporalmente, ascendió al cielo, y siendo nuestro Sumo Sacerdote, vive intercediendo y abogando por los verdaderos creyentes (Hechos 1:9,10; Romanos 8:34; Hebreos 2:17; 7:25; 1 Juan 2:1,2).", id: 5 },
    { content: "6. CREEMOS en el inminente arrebatamiento de la Iglesia y que el Señor Jesucristo vendrá en la gloria de su Padre para establecer en el mundo su reino de justicia y paz, y que su venida será personal, visible y premilenial (Mateo 24:36,42; Hechos 1:11; 1 Tesalonicenses 4:14-17; Apocalipsis 20:4).", id: 6 },
    { content: "7. CREEMOS que el Espíritu Santo es una Persona y es Dios mismo, igualmente que el Padre y el Hijo. Convence al incrédulo de pecado, de justicia y de juicio. Al momento de creer en Cristo, aceptándolo como su salvador personal, el creyente es regenerado, sellado y bautizado por el Espíritu Santo, quien viene en ese momento a morar en él para siempre (Juan 16:8-11; Hechos 5:3,4; 1 Corintios 6:19; 12:13; 2 Corintios 3:6; Romanos 8:9; Efesios 4:30).", id: 7 },
    { content: "8. CREEMOS que el Espíritu Santo soberanamente da dones a la Iglesia para su crecimiento y desarrollo espiritual pero no para comprobar la espiritualidad del creyente. Creemos que los dones espectaculares tales como el hablar en lenguas, la sanidad, y milagros, sirvieron principalmente como señales para confirmar el testimonio del evangelio en la fundación de la iglesia. Creemos que estos dones nunca fueron una señal necesaria del bautismo ni de la llenura del Espíritu (Hechos 4:8,31; 1 Corintios 13:8; Efesios 4:11-16; Hebreos 2:4).", id: 8 },
    { content: "9. CREEMOS que Dios sana en respuesta a la oración, con o sin medios, de acuerdo a su soberana voluntad. Cuando Dios no sana, esto no es necesariamente una indicación de falta de fe ni de pecado en la vida del creyente. Igualmente, Dios en su soberanía obra milagros de acuerdo a su voluntad y propósitos (Juan 9:2,3; 2 Corintios 12:7-10; Santiago 5:14,15; 1 Juan 5:14,15).", id: 9 },
    { content: "10. CREEMOS que el hombre es creado a la imagen de Dios, que pecó y así acarreó la muerte física y la muerte espiritual, la cual es la separación de Dios. Todos los seres humanos nacen con una naturaleza pecaminosa que resulta en hechos de pecado. Creemos que el pecado de Adán se inculpa a cada persona individualmente y por lo consiguiente el hombre esta bajo la condenación de Dios (Génesis 1:27, Romanos 3:23; 5:12; 6:23; 2 Tesalonicenses 1:8,9; Efesios 2:1-3,12).", id: 10 },
    { content: "11. CREEMOS que todo aquel que recibe a Jesucristo por la fe, no apoyándose en ninguna obra propia, “nace de nuevo”, y así se hace hijo de Dios, “creado en Cristo Jesús para buenas obras”, tiene la vida eterna y no está bajo condenación (Juan 1:12; 3:3,16; Romanos 3:28; Efesios 2:8-10; Juan 5:24).", id: 11 },
    { content: "12. CREEMOS que todos los redimidos están guardados y eternamente seguros en Cristo por el poder de Dios a través del nuevo nacimiento, por la morada y el sello del Espíritu Santo, y por la intercesión de Cristo (Juan 5:24; 10:28; Romanos 8:1,38,39; Efesios 1:13; 1 Pedro 1:3-5).", id: 12 },
    { content: "13. CREEMOS que todos los verdaderos creyentes todavía son afectados por la antigua naturaleza pecaminosa, pero que Dios ha hecho amplia provisión por la cual sus hijos pueden ser más que vencedores por medio del Señor Jesucristo y por el poder del Espíritu Santo quien mora en cada uno de ellos (Juan 7:38; Romanos 8:9,37; Gálatas 5:16; 1 Juan 1:8).", id: 13 },
    { content: "14. CREEMOS que la Iglesia es universal en su forma invisible, que es el Cuerpo de Cristo. Creemos que todos los verdaderos creyentes forman parte de este Cuerpo. Creemos que Cristo ordenó que practicáramos el Bautismo por inmersión en agua y la Santa Cena. Creemos que la Iglesia Local es la manifestación visible del Cuerpo de Cristo y existe para la adoración a Dios, la edificación, la comunión entre hermanos, y la evangelización del mundo. La Iglesia Local tiene un gobierno autónomo y su autoridad está fundada en las Sagradas Escrituras para ejercer la disciplina y velar por el bien de los hermanos (Mateo 18:20; Hechos 2:41,42; 13:1-4; 20:28; Romanos 16:1,4; Efesios 1:19-23; 5:25-27; 1 Corintios 5:4-7,13; 11:23-26; 12:13; 1Pedro 5:1-4).", id: 14 },
    { content: "15. CREEMOS en la resurrección corporal de los justos y de los injustos, la eterna felicidad de los salvos y el eterno castigo de los perdidos (Mateo 25:46; Juan 5:28,29; 11:25,26; Apocalipsis 20:5,6,12,13).", id: 15 },
    { content: "16. CREEMOS en la personalidad y la realidad de Satanás. Creemos que él tiene siervos que le ayudan a promover lo malo, tales como demonios, espíritus malignos y aún personas (Job 1:6,7; Mateo 4:2-11; 5:41; Efesios 6:12; Apocalipsis 20:10).", id: 16 },
    { content: "17. CREEMOS que Dios ha establecido el poder civil para definir y hacer observar los deberes de los hombres entre sí, y por lo tanto el cristiano debe respetar y cumplir las disposiciones de las autoridades en todo aquello que no contradiga la enseñanza de las Sagradas Escrituras (Romanos 13:1-7; Hechos 5:29).", id: 17 },
  ]; 

  // Define the fade-up animation
  const fadeUpVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.07 } // Delay each statement's animation based on its index
    }),
  };

  return (
    <div>
      <Navbar />
      <div className="bg-cover md:bg-fixed bg-center bg-[url('./assets/img/bible-opened.jpg')] py-32 w-full shadow-lg">
        <div className="grid grid-cols-6 animate-fade">
          <div></div>
          <div className="xl:col-span-4 lg:col-span-4 col-span-full">
            <h1 className="text-4xl text-center font-semibold mb-6 text-gray-200">Nuestra Fe</h1>
            <p className="text-white text-center text-2xl font-light tracking-wide mb-10">Nuestra Iglesia se cimenta firmemente en las Santas Escrituras.</p>
          </div>
          <div></div>
        </div>
      </div>
      {/** Faith statements document section */}
      <div className="grid grid-cols-6 bg-gray-300">
        <div></div>
        <div className="xl:col-span-4 lg:col-span-4 col-span-full">
          {/** Progress bar */}
          <motion.div
            style={{
              scaleX: scrollYProgress,
              backgroundColor: 'red',
              height: '7px',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              zIndex: 1000, // Ensures the progress bar is above other content
              originX: 0, // Sets the origin to the left side
            }}
            initial={{ scaleX: 0 }} // Start from 0 scale
            animate={{ scaleX: scrollYProgress }} // Animate to the current scroll progress
            transition={{ duration: 0.1 }} // Adjust the duration for smooth animation
          />
          {/** Faith statements rendering */}
          <div className="py-16 px-12 text-gray-800 shadow-2xl bg-gray-100 rounded">
            {
              faith_statements.map((statement, index) => (
                <motion.p 
                  key={statement.id} 
                  className={li_style} 
                  variants={fadeUpVariant}
                  initial="hidden" 
                  whileInView="visible" 
                  custom={index} // Pass index to customize delay
                  viewport={{ once: true }} // Animation happens once when it comes into view
                >
                  {statement.content}
                </motion.p>
              ))
            }

            {/* Signature of the Document */}
            <p className="text-right text-5xl pt-8 font-purxious">Ps. Robero Zamora.</p>
          </div>   
        </div>
        <div></div>
      </div>
      <Foot />
    </div>
  );
};
