import React, { useRef } from 'react';
import { HeroMain } from '../components/HeroMain';
import { Foot } from '../components/Foot';
import location from "./../assets/img/location.png";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';

export const Inicio = () => {
  const redirect = () => {
    window.open("https://maps.app.goo.gl/zuhVadSsg3atsZdx6");
  };

  // Fade-up animation variants
  const fadeUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.7 } },
  };

  // Control animations and in-view detection
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true, 
    threshold: 0.1,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  // Create a ref for the Mision section
  const misionRef = useRef(null);

  return (
    <div>
      <div className="bg-gray-100">
        {/* Pass misionRef as a prop to HeroMain */}
        <HeroMain scrollToMisionRef={misionRef} />

        {/* Mission of the church */}
        <button id="mision" ref={misionRef} href="#"></button>
        <motion.div 
          className="grid grid-cols-6 py-20 w-full"
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={fadeUp}
        >
          <div></div>
          <div className="text-center xl:col-span-4 lg:col-span-4 col-span-full px-8">
            <p className="md:text-4xl text-2xl font-semibold mb-6 text-gray-500 leading-tight">
              Somos una iglesia donde cada persona es un discípulo de Cristo, que ama a sus hermanos y alcanza a un mundo que necesita amor.
            </p>
            <p className="md:text-2xl text-xl font-light">
              Capacitamos a cada creyente para que impacte a otros con una vida transformada en Dios.
            </p>
          </div>
          <div></div>
        </motion.div>

        {/* Reunions and Location */}
        <motion.div 
          className="grid grid-cols-6 py-20 bg-slate-200 w-full md:px-8 sm:px-8"
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={fadeUp}
        >
          <div></div>
          <div className="xl:col-span-4 lg:col-span-4 col-span-full">
            <h2 className="md:text-4xl text-2xl mb-6 text-center font-semibold text-gray-500">
              Reuniones AMAD
            </h2>
            <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2">
              <div className="self-center px-8 mb-6">
                <p className="font-normal md:text-2xl text-xl mb-6 text-center">Domingo 11:00 a.m.</p>
                <p className="font-light md:text-xl text-lg text-center">
                  Prolongación 31 Pte. 4705, Reforma, 72160 Heróica Puebla de Zaragoza, Pue. Jardín de Eventos "Monarca".
                </p>
                <div className="flex justify-center mt-4">
                  <button 
                    type="button" 
                    onClick={redirect} 
                    className="border-2 border-black bg-black text-white hover:bg-slate-400 hover:border-slate-400 hover:text-white transition-all px-8 py-2 rounded text-lg font-semibold flex-row flex shadow"
                  >
                    Mapa
                  </button>
                </div>
              </div>
              <div className="shadow-lg">
                <img src={location} alt="Ubicación de Iglesia" className="rounded-sm object-cover w-full h-full" />
              </div>
            </div>
          </div>
          <div></div>
        </motion.div>
      </div>
      <Foot />
    </div>
  );
};
